.vibeonix-buttonloader {
  .first-circle {
    animation-delay: 0.1s;
  }
  .second-circle {
    animation-delay: 0.2s;
  }
  .third-circle {
    animation-delay: 0.3s;
  }
  .fourth-circle {
    animation-delay: 0.4s;
  }
  .fifth-circle {
    animation-delay: 0.5s;
  }
}

.vibeonix-pageloader {
  height: calc(100vh - 150px);
  .first-circle {
    animation-delay: 0.1s;
  }
  .second-circle {
    animation-delay: 0.2s;
  }
  .third-circle {
    animation-delay: 0.3s;
  }
  .fourth-circle {
    animation-delay: 0.4s;
  }
  .fifth-circle {
    animation-delay: 0.5s;
  }
}

.vibeonix-dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3A9EA5;
  color: #3A9EA5;
}

.vibeonix-dot-collision::before,
.vibeonix-dot-collision::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.vibeonix-dot-collision::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3A9EA5;
  color: #3A9EA5;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.vibeonix-dot-collision::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3A9EA5;
  color: #3A9EA5;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

.dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
}

.dot-collision::before,
.dot-collision::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

/**
 * ==============================================
 * Dot Spin
 * ==============================================
 */
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #3A9EA5, 12.72984px -12.72984px 0 0 #3A9EA5,
    18px 0 0 0 #3A9EA5, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #3A9EA5, 12.72984px -12.72984px 0 0 #3A9EA5,
      18px 0 0 0 #3A9EA5, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #3A9EA5, 18px 0 0 0 #3A9EA5,
      12.72984px 12.72984px 0 0 #3A9EA5, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #3A9EA5,
      12.72984px 12.72984px 0 0 #3A9EA5, 0 18px 0 0 #3A9EA5,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #3A9EA5,
      0 18px 0 0 #3A9EA5, -12.72984px 12.72984px 0 0 #3A9EA5,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #3A9EA5,
      -12.72984px 12.72984px 0 0 #3A9EA5, -18px 0 0 0 #3A9EA5,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #3A9EA5,
      -18px 0 0 0 #3A9EA5, -12.72984px -12.72984px 0 0 #3A9EA5;
  }
  75% {
    box-shadow: 0 -18px 0 0 #3A9EA5,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #3A9EA5,
      -12.72984px -12.72984px 0 0 #3A9EA5;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #3A9EA5, 12.72984px -12.72984px 0 0 #3A9EA5,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #3A9EA5;
  }
}

/**
 * ==============================================
 * Round Spin
 * ==============================================
 */
 .l-spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3A9EA5;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes l-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
