.input-width {
  width: 20rem;
}

.iu-line-color {
  border-bottom: 1px solid #3A9EA5;
  width: 120px;
}

.iu-font-color {
  color: rgba(58, 158, 165, 1) !important;
}

.feature-list{
    max-width: 390px;
}

.user-bg {
    background-image: url('../../assets/images/svg/PatternBg.svg') !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    background: #F2F7FA;
    background-size: cover;
    background-attachment: fixed;
  }

  .vibeonix-color{
      color: rgba(67, 94, 190, 1);

  }
  
  .card-container {
    min-height: 40rem;
  }

  .card-item {
    background: #F5F7FF;
    border: 1px solid #EDF0F9;
    box-sizing: border-box;
    border-radius: 12px;
  }