.vibeonix-pagination{
    display: flex !important;
    font-size: 15px !important;
    align-items: center !important;
    grid-gap: 1rem;

    .active-page{
        color: #3A9EA5 !important;
        text-decoration: underline !important;
    }
    li{
        margin-left: 4px !important;
    }
}