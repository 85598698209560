.editBlockDeleteTooltop {
  .__react_component_tooltip {
    padding: 5px !important;
  }
}
.editBlocktooltip {
  .__react_component_tooltip {
    padding: 5px !important;
  }
}

.__react_component_tooltip {
    padding: 5px !important;
}