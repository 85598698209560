.popup-wrapper {
    background: rgba(0, 0, 0, 0.5);
    z-index: 99998;
    .popup-container {
      max-width: 480px;
      z-index: 12345;
      padding: 0px 15px;
      .img {
        top: -4px;
        right: 10px;
        height: 32px;
        width: 32px;
      }
      .popup-inner-container{
        border-radius: 32px;
      }
      &.mw-700 {
        max-width: 900px;
      }
      &.mw-594 {
        max-width: 594px;
      }
      &.mw-480 {
        max-width: 480px;
      }
      .max-h-340 {
        max-height: 340px;
      }
      .max-h-160 {
        max-height: 160px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #c4c4c4;
          border-radius: 14px;
        }
        &::-webkit-scrollbar-thumb {
          background: #717171;
          border-radius: 14px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
      & > div.bg-white {
        box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
      }
      .chkbox {
        span {
          border-radius: 2px;
        }
        input:checked + span {
          // background-size: 9px;
          // border-color: #00a49f;
        }
      }
      .placeholder-txt {
        color: #b4b4b4;
      }
      input:focus {
        // border: 1px solid #2da39a;
      }
      .popup-btn {
        button {
          box-shadow: 0px 6px 34px rgba(26, 19, 70, 0.12);
          min-width: 140px;
        }
      }
      .text-dark-grey {
        color: #383839;
      }
    }
    & > div.bg-white {
      box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
    }
    .chkbox {
      span {
        border-radius: 2px;
      }
      input:checked + span {
        background-size: 9px;
      }
    }
    .placeholder-txt {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .togglediv {
    background: #fff8f4;
    border: none;
    padding: 15px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggleslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .toggleslider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  // input:checked + .toggleslider {
  //   background-color: #eb902d;
  // }
  
  input:focus + .toggleslider {
    box-shadow: 0 0 1px #eb902d;
  }
  
  input:checked + .toggleslider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(0px);
    transform: translateX(20px);
  }
  
  /* Rounded togglesliders */
  .toggleslider.round {
    border-radius: 20px;
  }
  
  .toggleslider.round:before {
    border-radius: 50%;
  }

  @media screen and (min-height : 650px) {
    .input-margin {
      margin-top : 10px
    }
    .button-margin {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  @media screen and (min-height : 700px) {
    .input-margin {
      margin-top : 15px
    }
    .button-margin {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  @media screen and (min-height : 800px) {
    .input-margin {
      margin-top : 25px
    }
    .button-margin {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  