.MuiTextField-root{
    width: 100%;
}
.MuiPickersToolbar-toolbar,.MuiPickersDay-daySelected{
    background-color:var(--bg-background-secondary) !important
}
.MuiButton-textPrimary{
    color: var(--bg-background-secondary) !important;
}

.MuiTypography-h4{
    font-size: 1.125rem !important;
}
.MuiButton-label{
    h6{
        font-size: 2rem !important;
        color: #fff !important;
    }
    
}
.MuiFormHelperText-root.Mui-error{
    margin-left: auto;
}

.MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid red !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
    border-bottom: 2px solid #e2e8f0 !important;
}