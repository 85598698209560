.MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}
.dropdown-list-location-details {
  max-height: 213px;
  top: 36px;
  width: 180px;
  border-radius: 5px;
  margin-top: 10px;
}
.container-color {
  background-color: #e2e8f0;
  font-family: 'Lexend-Light';
  max-width: 40%;
  overflow: hidden;
}
.label-style {
  font-family: 'Lexend-Light';
  color: #444c63;
}
.custom-select-container {
  max-width: 180px;
}
.container {
  height: 40px;
}
.MuiInputLabel-root,
.MuiInputBase-root {
  // font-family: Lexend !important;
  color: #444c63 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiInput-underline:after {
  border-color: var(--bg-background-secondary) !important;
}
.MuiSelect-nativeInput,
.MuiInput-input,
.MuiInput-input,
.MuiSelect-select,
.MuiSelect-nativeInput,
.MuiSelect-icon {
  font-size: 16px !important;
  font-family: Lexend-Light !important;
  color: var(--text-copy-secondary) !important;
}
.MuiMenuItem-root {
  font-size: 16px !important;
  font-family: Lexend-Light !important;
  color: var(--text-copy-secondary) !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:before {
  border-bottom: 1px solid #e2e8f0 !important;
}

.MuiTypography-body1 {
  font-family: Lexend-Light !important;
}
.makeStyles-chips-2 {
  flex-wrap: nowrap !important;
}
.MuiChip-label {
  font-size: 12px !important;
}
.MuiChip-root {
  height: auto !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3a9ea5 !important;
}
.MuiIconButton-colorSecondary {
  color: #444c63 !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  display: flex;
}

.css-2613qy-menu,
.css-2b097c-container {
  z-index: 9999;
}
.css-2b097c-container,
.css-yk16xz-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  svg {
    fill: #444c63 !important;
    width: 17px !important;
    height: 20px !important;
  }
}
.css-1pahdxg-control {
  box-shadow: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  &:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    // border-bottom: 1px solid orangered !important;
  }
}
.css-1pahdxg-control {
  border-bottom: 1.5px solid var(--bg-background-secondary) !important;
  &:focus {
    border-bottom: 1.5px solid var(--bg-background-secondary) !important;
  }
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
  .css-1uccc91-singleValue {
    color: #444c63 !important;
    font-family: Lexend-Light !important;
  }
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-1wa3eu0-placeholder {
  color: #444c63 !important;
  font-family: Lexend-Light !important;
}

.muiltiselectdropdown {
  .css-yk16xz-control,
  .css-1pahdxg-control {
    height: 50px;
    // overflow-y: scroll;
  }
  .css-1rhbuit-multiValue {
    border-radius: 50px;
  }
  .css-12jo7m5 {
    font-size: 12px !important;
    padding: 0 !important;
  }
}

.MuiMenu-list {
  max-height: 15rem;
  overflow: auto;
}
@media (max-width: 380px) {
  .css-1wa3eu0-placeholder {
    font-size: 9px;
  }

  .css-1uccc91-singleValue{
    font-size: 9px;
  }
}