.dropdown-list {
  top: 42px
    }
  .groupname-dropdown-list {
    top: 32px;
    right: -124px;
  }
  .user-menu {
    top: 22px
  }
  .user-edit-container{
    width: 100px;
    height: 50px;
  }
  .menu-dropdown{
    width: 100px;
    height: 40px;
    top: 32px;
    left: -90px;
    padding-top: 10px;
    padding-bottom: 10px;
    // padding-right: 4px;
    padding-left: 4px;
  }
  .menu-dropdown-line {
    width: 1px;
  }
  // .container-row{
  //   width: 100%;
  // }
  .row-one {
  width: 5%;
  height: 46px;
  }
  .row-two {
    width: 15%;
    height: 46px;
  }
  .row-three {
    width: 15%;
    height: 46px;
  }
  .row-four {
    width: 20%;
    height: 46px;
  }
  .row-five {
    width: 30%;
    height: 46px;
    .number-container {
      width: 15px;
      height: 15px;
      background-color: black;
      border-radius: 10px;
      margin-left: 5px;
      justify-content:flex-end;
      align-items: center;
      display: flex;
      padding-right: 2px;
      .number-text {
        font-size: 10px;
      }
      .plus{
        font-size: 9px;
      }
    }
  }
  .row-six {
    width: 10%;
    height: 46px;
  }
  .row-seven {
    width: 5%;
    height: 46px;
  }
  .table-container {
    height: 350px;
    /* width */
  ::-webkit-scrollbar {
    // width: 20px !important; 
    height: 8px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey; 
    border:1px solid #28b5bd;
    border-radius: 10px;
    background-color: #ECF8F8 ;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #28b5bd; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #28b5bd; 
  }
    tr:nth-child(even) {
      background-color: #F5F7FF;
      ;
  }
  tr:nth-child(od) {
      background-color: #fff;
  }
  }
  .horizontal-scrollbar {
  /* width */
::-webkit-scrollbar {
  // width: 4px !important; 
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: solid 2px #6BB7BB;
  background-color: #ECF8F8;
}
tr:nth-child(od) {
background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6BB7BB; 
  border-radius: 10px;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #b30000; 
// }
//   tr:nth-child(even) {
//     // background-color: #F5F7FF;
// }
// tr:nth-child(od) {
//     // background-color: #fff;
// }
}
.horizontal-scrollbar {
/* width */
// ::-webkit-scrollbar {
//   width: 20px; 
//   height: 20px !important;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey; 
//   border-radius: 10px;
// }
 
/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: red; 
//   border-radius: 10px;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #b30000; 
// }
}




.headcol {
  position: absolute;
    right: 0;
    // background: red;
    justify-content: center;
    display: flex;
}

.headcolleft {
  position: absolute;
    left: 0;
    // background: red;
    justify-content: center;
    display: flex;
}

.border-user{
  border: 1px solid #EDF0F9 !important;
  border-radius: 20px;
}
.top-border-radius{
  border-top-right-radius: 20px;
}

.secondcolor{
 background-color: #F5F7FF !important;
}
.text-underline-position{
  text-underline-position: under;
}

::-webkit-scrollbar{
  height: 8px;
  width: 1px;
  background: #fff;
}
::-webkit-scrollbar-thumb:horizontal{
  background:#6BB7BB;
  border-radius: 10px;
}
