.popup-wrapper-confirm {
  background: rgba(0, 0, 0, 0.5);
  z-index: 99998;
  .popup-container-confirm {
    width: 454px;
    // height: 402px;
    z-index: 12345;
    padding: 0px 15px;
    .img {
      top: -8px;
      right: 3px;
      height: 22px;
    }
    .popup-inner-container {
      border-radius: 20px;
    }
    // &.mw-700 {
    //   max-width: 900px;
    // }
    // &.mw-594 {
    //   max-width: 594px;
    // }
    // &.mw-480 {
    //   max-width: 480px;
    // }
    // .max-h-340 {
    //   max-height: 340px;
    // }
    // .max-h-160 {
    //   max-height: 160px;
    //   overflow-y: auto;
    //   &::-webkit-scrollbar {
    //     width: 6px;
    //   }
    //   &::-webkit-scrollbar-track {
    //     background: #c4c4c4;
    //     border-radius: 14px;
    //   }
    //   &::-webkit-scrollbar-thumb {
    //     background: #717171;
    //     border-radius: 14px;
    //   }
    //   &::-webkit-scrollbar-thumb:hover {
    //     background: #555;
    //   }
    // }
    & > div.bg-white {
      box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
    }
    .chkbox {
      span {
        border-radius: 2px;
      }
      input:checked + span {
        // background-size: 9px;
        // border-color: #00a49f;
      }
    }
    .placeholder-txt {
      color: #b4b4b4;
    }
    input:focus {
      // border: 1px solid #2da39a;
    }
    .popup-btn {
      button {
        box-shadow: 0px 6px 34px rgba(26, 19, 70, 0.12);
        min-width: 140px;
      }
    }
    .text-dark-grey {
      color: #383839;
    }
  }
  & > div.bg-white {
    box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
  }
  .chkbox {
    span {
      border-radius: 2px;
    }
    input:checked + span {
      background-size: 9px;
    }
  }
  .placeholder-txt {
    color: rgba(0, 0, 0, 0.5);
  }
}
