.main-container {
  background: #3A9EA5 !important;
  //margin-top: -23px;
}
.mobile-container {
  background: #161f38;
  opacity: 0.3;
}
.sidebar-profile {
  height: 111px;
  @media (min-width: 640px) {
    border-bottom-width: 1px;
  }
}
.topbar-profile {
  // border-top: 0.5px solid;
  @media (max-width: 640px) {
    minHeight: 35px;
    border-bottom-width: 1px;
    border-color: #edf0f9;
    margin-top: 1rem;
    // padding-left: .4rem;
    // padding-right: .4rem;
  }
  @media (min-width: 640px) {
    min-height: 111px;
  }
}
.menu-card {
  // border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 9px;
  // background: #ffffff;
  margin: 0px;
  margin-top: 20px;
  // &:nth-child(-n + 2){
  //     margin-top: 0px;
  // }
  width: 67%;
  margin-left: 30px;
  h1 {
    // color: #676e8b;
    // font-weight: 500;
    // font-size: 15px;
    // line-height: 18px;
  }
  svg {
    fill: #676e8b;
  }
  &:hover {
    // background: #107ec3;
    // border: 1px solid #107ec3;
    // box-sizing: border-box;
    // color: #fff;
    // h1 {
    //     color: #FFFFFF;
    // }
    svg {
      fill: #ffffff;
    }
  }
}
.activeClass {
  // background: #107ec3;
  // border: 1px solid #107ec3;
  box-sizing: border-box;
  color: #fff;
  h1 {
    color: #ffffff;
  }
  svg {
    fill: #ffffff;
  }
}
.mobile-active-class {
  color: rgba(65, 138, 23, 0.3);
}
.menu-items {
  overflow: auto;
  // max-height: 20rem;
  transition: color 0.3s ease;
  color: rgba(0, 0, 0, 0.3);
  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }
}
.items-menu {
  // max-height: 20rem;
  transition: color 0.3s ease;
  color: rgba(0, 0, 0, 0.3);
  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }
}
.right-col {
  // height: fit-content;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  @media (min-width: 768px) {
    // border-bottom-left-radius: 25px;
    border-top-right-radius: 0px;
  }
  background-color: #ffffff;
}

.PhoneInputInput {
  background: none !important;
}