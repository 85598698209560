.inner-container {
  background-color: #f2f7fa;
}
.top-left-box {
  // height: 214px;
  box-shadow: 0px 2px 4px 2px rgba(188, 200, 204, 0.28);
  .h-half {
    height: 50%;
  }
  .individual-card {
    height: 38px;
    background-color: #161f38;
  }
  // .dollar {
  //   @media (min-width: 800px) {
  //     font-size: 60px;
  //   }
  //   @media (max-width: 768px) {
  //     font-size: 40px;
  //   }
  //   @media (max-width: 640px) {
  //     font-size: 30px;
  //   }
  //   @media (max-width: 400px) {
  //     font-size: 22px;
  //   }
  // &.mw-700 {
  //     font-size: 50px;
  //   }
  //   &.mw-594 {
  //     font-size: 20px;
  //   }
  // //   &.mw-480 {
  // //     max-width: 480px;
  // //   }
  //   color: #444c63;
  // }
}
.top-right-box {
  height: 214px;
  background-color: white;
  box-shadow: 0px 2px 4px 2px rgba(188, 200, 204, 0.28);
}
.dollar-sign {
  font-size: 26;
  @media (max-width: 400px) {
    font-size: 16;
  }
  color: '#444C63';
}
.monthly {
  font-size: 16;
  @media (max-width: 400px) {
    font-size: 10;
  }
  color: '#444C63';
}
.upgrade-plan {
  width: 346px;
  height: 100%;
  max-height: 352px;
}

.invoice-container {
  max-height: 20rem;
}

// input[type='radio']:after {
//   width: 15px;
//   height: 15px;
//   border-radius: 15px;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: white;
//   content: '';
//   display: inline-block;
//   visibility: visible;
//   border: 1px solid black;
// }

// input[type='radio']:checked:after {
//   width: 15px;
//   height: 15px;
//   border-radius: 15px;
//   top: -2px;
//   left: -1px;
//   position: relative;
//   background-color: #3a9ea5;
//   content: '';
//   display: inline-block;
//   visibility: visible;
//   border: 3px solid white;
// }

.upgrade-plan-container {
  max-width: 454px !important;
}

.min-w-32 {
  min-width: 8rem;
}

.invoice-no-data-image {
  height: 100%;
}

.congrats-icon {
  height: 400px;
}


