.logoutPopup{
    .activebutton{
            border: 1px solid #3A9EA5 !important;
            background-color: #E1E5F5 !important;
    }
    
}
// .logoutPopover{
//     .MuiPopover-paper{
//         margin-top: 10px;
//         &::before{
//             content: "* * *";
//     display: flex;
//     justify-content: end;
//     width: fit-content;
//     margin-left: auto;
//     background: aquamarine;
//         }
//     }
// }
