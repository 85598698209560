.happiness {
    background-color: #DF8B6E;
}
.motivation {
    background-color: #BDD6AC;
}
.confidence {
    background-color: #91B3CB;
}
.fear {
    background-color: #82829C;
}
.loneliness {
    background-color: #BEBEBE;
}
.stress {
    background-color: #C5595F;
}
