.chat-message-section {
	margin-top: -25px;
}
footer {
	position: relative !important;
}
.ql-toolbar.ql-snow {
	background: rgb(236, 248, 248);
}
.individual-active {
	background: #eff2ff;
}
.user:hover {
	background: #eff2ff;
}

.group:hover {
	background: #eff2ff;
}
.user-chat {
	background-color: #fff;
	background-color: var(--bs-card-bg);
	/* 	box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12); */
	transition: all 0.4s;
	overflow: auto;
}
.chat-leftsidebar {
	max-width: 300px;
	min-width: 300px;
	height: 100vh;
	overflow: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.user-chat header div div span{
	color: #3A9EA5;
}

.user-chat-header Header {
	display: none;
}
.chat-holder {
	height: calc(100vh - 490px);
	padding-bottom: 130px;
	overflow: auto;
}
.search-error>.viveonixSearchBox {
	border: 1px solid red;
	border-bottom: 1px solid red;
}
.search-error>.viveonixSearchBox:focus-within {
	border-bottom: 1px solid red !important;
}
.error-message{
	display: none;
}
.search-error>.error-message {
	font-size: 10px;
	text-align: end;
	padding: 3px;
	display: block !important;
	color: red;
}

.search-error-bottom>.viveonixSearchBox {
	border-bottom: 1px solid red;
}
.search-error-bottom>.viveonixSearchBox:focus-within {
	border-bottom: 1px solid red !important;
}
.error-message-bottom{
	display: none;
}
.search-error-bottom>.error-message {
	font-size: 10px;
	text-align: end;
	padding: 3px;
	display: block !important;
	color: red;
}

.user-chat-header {
	display: flex;
}

.user-chat-header img {
	display: none;
}

.chat-leftsidebar > .viveonixSearchBox {
	background-color: #ecf8f8 !important;
	border-radius: 15px;
	padding: 7px;
}
.chat-leftsidebar > .viveonixSearchBox > input {
	background-color: #ecf8f8 !important;
}

.desktop-editor > .quill > .ql-toolbar.ql-snow {
	border-bottom: 0 !important;
}
.chat-inner-box {
	padding: 10px;
}
.chat-leftsidebar > div >div> .viveonixSearchBox {
	background-color: #ecf8f8 !important;
	border-radius: 15px;
	padding: 2px;
	margin-top: 32px;
	padding-left: 10px;
}
.chat-leftsidebar > div >div> .viveonixSearchBox > input {
	background-color: #ecf8f8 !important;
}

.chat-leftsidebar > div >.viveonixSearchBox {
	background-color: #ecf8f8 !important;
	border-radius: 15px;
	padding: 2px;
	margin-top: 32px;
	padding-left: 10px;
}
.chat-leftsidebar > div > .viveonixSearchBox > input {
	background-color: #ecf8f8 !important;
}
.desktop-editor {
	display: block;
}
.mobile-editor {
	display: none;
}

@media (min-width: 750px) {
	.user-chat.user-chat-show {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		visibility: visible;
	}
	.user-chat.user-chat-hide {
		-webkit-transform: translateX(1);
		transform: translateX(1);
		visibility: visible;
	}
}
@media (max-width: 750px) {
	.chat-message-section {
		margin-top: 0;
	}
	.mostly-customized-scrollbar{
		overflow-y: auto;
	 }
	.user-chat.user-chat-show {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		visibility: visible;

		position: absolute;
		background: white;
	}
	.user-chat.user-chat-hide {
		-webkit-transform: translateX(1);
		transform: translateX(1);
		visibility: hidden;

		position: absolute;
		background: white;
	}
	.chat-inner-box {
		padding: 3px;
	}
}

@media (max-width: 380px) {
	.chat-leftsidebar > .topbar-profile {
		overflow: initial !important;
	}
}
@media (max-width: 750px) {
	.layoutside {
		border-radius: 0 !important;
	}
	.chat-leftsidebar {
		min-width: 100% !important;
		padding-top: 0px !important;
	}

	.user-chat {
		height: 100%;
		left: 0;
		position: relative;
		top: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		visibility: hidden;
		width: 100%;
		z-index: 99;
	}
	.user-chat-show {
		padding: 0;
		overflow-x: hidden;
		position: fixed !important;
	}
	.user-chat-show Header {
		display: none;
	}
	.user-chat-show div {
		border: 0;
	}
	.user-chat-header Header {
		display: flex;
		align-items: center;
		padding-bottom: 0 !important;
		margin-top: 0 !important;
	}
	.user-chat-header Header div .nav-title {
		display: none;
	}
	.user-chat-header Header div {
		display: flex;
		justify-content: end;
	}
	.user-chat-header {
		display: flex;
		align-items: center;
		border-radius: 0;
		padding-top: 20px;
	}
	.user-chat-header div {
		white-space: nowrap;
	}
	.user-chat-header img {
		display: block;
	}
	.desktop-editor {
		display: none;
	}
	.mobile-editor {
		display: block;
		background-color: #fff !important;
		-webkit-appearance: none;
	}
	.mobile-editor > .quill {
		display: flex;
		flex-direction: column-reverse;
		background-color: #fff !important;
		z-index: 100000000;
	}
	.mobile-editor > .quill > .ql-container {
		height: 71px !important;
		padding: 9px;
		border:0;
		background-color: #fff !important;
	}
	
	.mobile-editor > .quill > .ql-container > .ql-editor {
		background: #ecf8f8;
		border-radius: 41px;
		width: 86%;
		display: grid;
		align-items: center;
		overflow-x: hidden;
		border: 1px solid #dadada;
		display: flex;
		align-items: center;
	}
	.mobile-editor > .quill > .ql-container > .ql-editor.ql-blank::before {
		padding-left: 12px;
	}
	.mobile-editor > .quill > .ql-toolbar.ql-snow {
		background-color: #000000; /* set the background color of the toolbar to light gray */
		display: flex;
		justify-content: space-around;
		padding: 5px !important;
		border: 0 !important;
		position: relative;
		left: -1px;
	}
	.mobile-editor
		> .quill
		> .ql-toolbar
		> .ql-formats
		> span
		> .ql-picker-label {
		color: white;
	}
	.chat-holder {
		height: calc(100vh - 175px);
		background-color: #fff;
	}

	.mobile-editor-hide > .quill > .ql-toolbar.ql-snow {
		display: none;
	}
}
