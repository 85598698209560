.Toastify__toast-container {
  width: auto !important;
  max-width: 420px !important;
  z-index: 99999 !important;
}

.Toastify__toast-body {
  width: fit-content !important;
  padding: 6px 35px 6px 0 !important;
}

.Toastify__toast::after {
  left: unset !important;
  padding-top: unset !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0;
  padding-right: 10px;
}

.Toastify__toast::before {
  top: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Toastify__toast {
  padding: unset !important;
}