.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
 .mostly-customized-scrollbar{
  overflow: hidden;
 }
 .mostly-customized-scrollbar:hover{
  overflow: auto;
 }
 .mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #ffffff; /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #6bb7bb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Lexend-Bold";
  font-weight: 700;
  src: local("Lexend-Bold"),
    url(./assets/fonts/Lexend-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-ExtraBold";
  font-weight: 800;
  src: local("Lexend-ExtraBold"),
    url(./assets/fonts/Lexend-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-Light";
  font-weight: 300;
  src: local("Lexend-Light"),
    url(./assets/fonts/Lexend-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-Medium";
  font-weight: 500;
  src: local("Lexend-Medium"),
    url(./assets/fonts/Lexend-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-Regular";
  font-weight: 400;
  src: local("Lexend-Regular"),
    url(./assets/fonts/Lexend-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-SemiBold";
  font-weight: 600;
  src: local("Lexend-SemiBold"),
    url(./assets/fonts/Lexend-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Lexend-Thin";
  font-weight: 100;
  src: local("Lexend-Thin"),
    url(./assets/fonts/Lexend-Thin.ttf) format("truetype");
}

.bg-primary {
  background-color: var(--bg-background-primary) !important;
}
.bg-primary-dark {
  background-color: var(--bg-background-primary-dark) !important;
}
.bg-primary-light {
  background-color: var(--bg-background-primary-light) !important;
}
.bg-secondary {
  background-color: var(--bg-background-secondary) !important;
}
.bg-secondary-dark {
  background-color: var(--bg-background-secondary-dark) !important;
}
.bg-secondary-light {
  background-color: var(--bg-background-secondary-light) !important;
}
.text-primary {
  color: var(--text-copy-primary) !important;
}
.text-bg-primary {
  color: var(--bg-background-primary) !important;
}
.text-bg-secondary {
  color: var(--bg-background-secondary) !important;
}
.text-secondary {
  color: var(--text-copy-secondary) !important;
}
.text-error {
  color: var(--bg-background-error) !important;
}
.bg-info {
  background: var(--bg-background-info) !important;
}
.bg-success {
  background: var(--bg-background-success) !important;
}
.bg-warning {
  background: var(--bg-background-warning) !important;
}
.bg-error {
  background: var(--bg-background-error) !important;
}
.border-b-error {
  border-bottom: 1px solid var(--bg-background-error) !important;
}
.bg-disable {
  background: var(--color-disable) !important;
}
.text-disable{
  color: var(--color-disable) !important;
}
.whitespace-nowrap{
  white-space: nowrap !important;
}

/* CALENDER */
.react-pick-date-container {
  min-width: 310px;
  max-width: 310px;
  border: 1px solid red;
}
.react-pick-date-main {
  background-color: #2b7479;
  padding: 20px 10px;
}
.react-pick-date-button {
  color: #009e6c;
  width: 75px;
  padding: 8px 6px;
}
.react-pick-date-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.react-pick-date-button-container {
  padding: 8px;
}
.react-pick-date-flex {
  display: flex;
}
.react-pick-date-justify-centere {
  justify-content: center;
}
.react-picker-year-view {
  justify-content: center;
  display: flex;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  color: #000;
  height: 40px;
  cursor: pointer;
}
.react-pick-date-selectedYear {
  color: #3f51b5;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.334;
  letter-spacing: 0em;
}
.react-pick-date-second-heading {
  font-size: 2.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  color: #fff;
  white-space: nowrap;
}
.react-pick-date-year {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  color: #fff;
}
.react-pick-date-flex-end {
  justify-content: flex-end;
}
.marginright-10 {
  margin-right: 10px;
}
.react-pick-date-marginLeft {
  margin-left: 5px;
}
.react-pick-color-black {
  color: #000;
}
.react-calender-view {
  display: flex;
  justify-content: space-between;
}
.react-pick-date-second {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  padding: 20px;
  color: #000;
}
.react-pick-date-w-100 {
  width: 100%;
}
.react-pick-date-td {
  width: 35px;
  margin: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #000;
}
.react-pick-date-td:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}
.react-pick-date-td:focus {
  background: salmon;
  border-radius: 2rem;
}
.react-pick-date-tr {
  display: flex;
  justify-content: space-around;
  height: 35px;
}
.react-pick-date-tr > th {
  color: rgba(0, 0, 0, 0.38);
}
.react-pick-date-height {
  height: 300px;
  overflow-y: scroll;
}
.today {
  background-color: #009e6c;
  color: #fff;
  border-radius: 2rem;
}

.in-prev-month,
.in-next-month {
  opacity: 0;
}

.react-date-pick-input {
  width: 100%;
  padding: 10px;
  border: 1px solid;
  color: #000;
}

.rounded-3xl{
  border-radius: 1.5rem !important;
}
.h-11{
  height: 2.75rem;
}
.truncateInfirstLine{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}


.active-bg{
  background-color: #EBF5F6 !important;
  color: #6BB7BB !important;
}
.blocked-bg{
  background-color: #FAE1E2 !important;
  color: #E3545D !important;
}
.pending-bg{
  background-color: #FADFC5 !important;
  color: #E78326 !important;
}
.reduce-opacity{
  opacity: 0.48 !important;
}
.react-tooltip::before{
	display: none;
}