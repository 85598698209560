.custom-popover {
	box-shadow: 0px 0px 4px rgba(68, 76, 99, 0.2);
	border-radius: 4px;
	color: #444C63;
	background: #FFFF;
	border: 1px solid transparent;
	border-radius: 3px;
	padding: 8px 21px;
	transition-delay: 3s;
}

.group-custom-popover{
	box-shadow: 0px 0px 4px rgba(68, 76, 99, 0.2);
	border-radius: 4px;
	color: rgb(68, 76, 99);
	background: #3A9EA5;
	border: 1px solid transparent;
	border-radius: 3px;
	padding: 8px 21px;
	transition-delay: 3s;
}

.custom-popover-arrow {
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	border-top-right-radius: 0px;
	border: 1px solid transparent;
	background-color: white;
	z-index: -2;
	top: 7px !important;
	box-shadow: 0px 0px 4px rgba(68, 76, 99, 0.2);
	left: 50% !important;
	margin-left: -6px;
	transform: rotate(45deg);
	opacity: 0;
}
.custom-popover{
  padding: 8px 9px !important;
}
.react-tiny-popover-container {
	top: -8px !important;
}
.popover-arrow-container {
	padding-bottom: 0px !important;
	animation-name: fadeIn;
	animation-duration: 0.5s;
}

.tool-item:hover{
	background-color: #435ebeaa;
}
.popover-arrow-item{
	border-bottom: 10px solid #3A9EA5 !important
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
