@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  font-family: Lexend-Regular, Arial, sans-serif;
  /* overflow: hidden; */
  background-color: #ffffff;
  font-size: 15px;
  /* color: black; */
}
.max-h-0 {
  max-height: 240px;
}
.ease.in {
  transition: all 1s ease-in-out;
}
.view-height {
  margin-top: 0%;
}

/* .canvas-container {
  position: relative;
  bottom: 170px;
  width: 80%;
  height: 22.5vh;
} */
.canvas-container-bar {
  height: 20vh;
}
.border-b-error {
  border-bottom: 1px solid #c62828;
}

.login-card {
  width: 416px;
}

.divHeight {
  height: 100vh;
}
.button-background-color {
  background: linear-gradient(0deg, #3a9ea5, #3a9ea5), #ffffff;
}
.card-color-primary {
  color: #3A9EA5;
}
.card-color-secondary {
  color: #8697d5;
}
.card-color-small {
  color: #3a9ea5;
}
.card-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 9px 2px rgba(67, 94, 190, 0.12);
  border-radius: 8px;
}
.card-shadow-table {
  background: #ffffff;
  box-shadow: 0px 2px 9px 2px rgba(67, 94, 190, 0.12);
  border-radius: 8px;
  clip-path: inset(-5px 0px -5px -5px);
  /* border-right: none; */
}

.card-min-h {
  min-height: 85%;
}

.shadow {
  cursor: pointer;
  width: 20%;
  padding: 1.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 9px 2px rgba(67, 94, 190, 0.12);
  border-radius: 8px;
}

hr.dashed-line {
  border-top: 1px dashed #dbdeea;
}

.teams-color {
  color: #3A9EA5;
}

.shadow_sub {
  cursor: pointer;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 9px 2px rgba(67, 94, 190, 0.12);
  border-radius: 8px;
}

table:nth-child(2) {
  background: #f5f7ff;
}

.center {
  left: 50%;
  top: 50%;
}

.table-head-bg {
  background-color: F5F7FF;
}

.hr-color {
  position: relative;
  border-bottom: 1px solid #3A9EA5;
  left: 144%;
}
.hr-color2 {
  border-bottom: 1px solid #3A9EA5;
}

.table-text-color {
  color: #444c63;
}
.button-text {
  color: #a6a9b4;
}
.trial-button-text {
  color: #3a9ea5;
}
.trial-button {
  background: #ffffff;
  border: 1px solid #3a9ea5;
  box-sizing: border-box;
  border-radius: 22px;
}
.contact-button {
  width: 168px;
  height: 44px;

  background: #3a9ea5;
  border: 1px solid #3a9ea5;
  box-sizing: border-box;
  border-radius: 22px;
}
.card-row-text {
  color: #161f38;
}
.tooltip-circle {
  position: relative;
  bottom: 35px;
  right: 15px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #3A9EA5;
}
.arrow-down {
  position: relative;
  bottom: 32px;
  left: 1.5px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid #3A9EA5;
}

.clicked-card-shadow {
  color: #fff;
  cursor: pointer;
  padding: 1.5rem;
  background: #3a9ea5;
  box-shadow: 0px 2px 9px 2px rgba(67, 94, 190, 0.12);
  border-radius: 8px;
}

input[type='text']::-ms-input-placeholder {
  text-align: right; /* for IE Edge */
}

.card-text-1 {
  font-size: 20px;
  font-weight: 500;
}
.card-text-2 {
  font-size: 16px;
  font-weight: 500;
}
.card-text-3 {
  font-size: 16px;
  font-weight: 500;
}

#package::-webkit-input-placeholder {
  /* Edge */
  text-align: right;
  background-color: 3a9ea5;
}
#package {
  background-color: #3a9ea5;
}

.background-primary {
  background-color: #3A9EA5;
}
.background-one {
  background-color: #444c63;
}
.background-grad {
  background: linear-gradient(0deg, #3a9ea5, #3a9ea5);
}
.background-grad-subscribe {
  background: linear-gradient(0deg, #2a3b79, #2a3b79);
}

.input-background {
  background-color: #3a9ea5;
}

.card-text-primary {
  color: #444c63;
}

.bg-mobile {
  background: #161f38;
}
.account-background {
  background: #f5f5f5;
}
.bg-indigo-600{
  background-color: #3A9EA5 !important;
}
button{

}


:root {
  --bg-background-primary:#3A9EA5 ;  /* #3A9EA5;*/
  --bg-background-primary-dark: #2a3b79;
  --bg-background-primary-light: #d1e6f0;

  --bg-background-secondary: #3a9ea5;
  --bg-background-secondary-dark: #2b7479;
  --bg-background-secondary-light: #ecf8f8;

  --text-copy-primary: #161f38;
  --text-copy-secondary: #444c63;

  --bg-background-info: #1e88e5;
  --bg-background-success: #33b339;
  --bg-background-warning: #fb8c00;
  --bg-background-error: #c62828;

  --color-disable: #bec2ce;
}

.slider {
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.55);
}
.profile-card {
  border-radius: 21px;
}
.profile-card img {
  filter: drop-shadow(0px -1px 5px rgba(0, 0, 0, 0.25));
}

.chat-box {
  background: RGBA(16, 126, 195, 0.08);
}

/* *::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
} */

/* *::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
} */

/* *::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
} */
/* 
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
} */
thead,
tbody tr {
  display: table;
  /* width: 1500px; */
  table-layout: fixed;
}

/* main:hover {
  color: rgba(0, 0, 0, 0.3);
} */

main {
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease;
}
*,
*:focus {
  outline: none !important;
}

.form-item {
  position: relative;
}
.form-item input {
  display: block;
  height: 40px;
  background: transparent;
  transition: all 0.3s ease;
  /* padding: 0 15px; */
  border-radius: 0px;
}
.form-item input:focus {
  border-color: var(--bg-background-secondary);
}
.form-item label {
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 0;
  background: transparent;
  /* padding: 0 10px; */
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.2px;
}
.form-item input:focus + label,
.form-item input:valid + label {
  font-size: 11px;
  top: -5px;
}
.form-item input:focus + label {
  color: blue;
}
.pinInput {
  border: 1px solid #ccd4de;
  box-sizing: border-box;
  border-radius: 2px;
  max-width: 75px;
  min-height: 40px;
}

.font-Lexend-Bold {
  font-family: Lexend-Bold !important;
}
.font-Lexend-ExtraBold {
  font-family: Lexend-ExtraBold !important;
}
.font-Lexend-Light {
  font-family: Lexend-Light !important;
}
.font-Lexend-Medium {
  font-family: Lexend-Medium !important;
}
.font-Lexend-Regular {
  font-family: Lexend-Regular !important;
}
.font-Lexend-SemiBold {
  font-family: Lexend-SemiBold !important;
}
.font-Lexend-Thin {
  font-family: Lexend-Thin !important;
}

/* Toast Styling */
/* .Toastify__toast--error {
  background: #f0f9fa !important;
  border: 1px solid #3a9ea5 !important;
  box-sizing: border-box !important;
  border-radius: 28px !important;
  color: #444C63  !important;
  font-family: "Lexend";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
} */

/* .Toastify__toast {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 26px rgba(235, 87, 87, 0.18);
  border-radius: 6px;
  padding: 20px;
  padding-left: 30px;

  font-family: "Lexend" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.Toastify__toast--success {
  background: #f0f9fa !important;
  border: 1px solid #3a9ea5 !important;
  box-sizing: border-box !important;
  border-radius: 28px !important;
  color: #444C63  !important;
  font-family: "Lexend" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.Toastify__toast--warn {
  background: #f0f9fa !important;
  border: 1px solid #3a9ea5 !important;
  box-sizing: border-box !important;
  border-radius: 28px !important;
  color: #444C63  !important;
  font-family: "Lexend" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
} */
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
  background: #fae1e2 !important;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 50px !important;
  background: #fadfc5 !important;
}
.Toastify__toast--error::before {
  content: url('../assets/images/svg/errorToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::before {
  content: url('../assets/images/svg/successToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::before {
  content: url('../assets/images/svg/warnToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
/* .Toastify__toast--error::after {
      content: 'Failed';
      position: absolute;
      color: #333333;
      font-size: 15px;
      font-weight: 700;
      left:85px;
      top: 2px;
    } */

/* .Toastify__toast--success {
    border: 1px solid #6FCF97;
    }
    .Toastify__toast--success::before {
      content: url("../assets/img/icons/add.svg");
      position:relative;
      z-index:100000; 
      left:-12px;
      top:10px;
    }*/
.Toastify__toast--success::after {
  content: url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::after {
  content: url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning::after {
  content: url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  /* padding-top: 25px; */
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
  /* content : url('../assets/images/svg/closeToast.svg') */
}
/* .Toastify__toast > button {
    background-image: url("../assets/img/icons/add.svg");
    background-size: cover;
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
.Toastify__toast > button > svg {
  display: none;
}

InputLabel {
  color: red !important;
}

/* SIDEBAR ANIMATION */

.sidebar__button {
  background: transparent;
  padding: 10px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  transition: 0.3s linear;
}

.sidebar {
  min-width: 250px;
  background: darkslategrey;
  padding: 20px;
  color: white;
  transition: 0.4s ease-in;
}

.sidebar-closed {
  min-width: 40px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.sidebar__listItem {
  margin: 30px 10px;
  display: flex;
  height: 20px;
}

.sidebar__listItem span {
  white-space: nowrap;
}

.sidebar__icon {
  margin: 0 15px 0 0;
}

/*Bootstrap Tooltip Arrow*/
.MuiTooltip-popper {
  margin-left: 22.3px;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: red !important;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: red !important;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: red !important;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: red !important;
}

/*Hover*/
.hover-style:hover {
  color: #3A9EA5;
  font-size: 17px;
}

.MuiDialog-root {
  z-index: 99999999999999 !important;
}
.limitWarning {
  border: 1px solid #f8b6b4 !important;
  background: #fef5f7;
}
.limitWarning-svg {
  background: #d32f2f !important;
}
.limitWarning-update-text {
  color: #d32f2f !important;
}

/* REMOVE INPUT TYPE NUMBER UP AND DOWN ARROW*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.light-payment-color {
  color: #6b7c93 !important;
}
.payment-card-shadow {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgb(22 32 64 / 20%);
}
.StripeElement--invalid {
  border: 1px solid #ef0a19 !important;
}
.StripeElement {
  box-sizing: border-box !important;
  height: 42px !important;
  padding: 14px !important;
  border: 1px solid transparent;
  border-radius: 4px !important;
  background-color: #fff !important;
  box-shadow: 0px 1px 4px rgba(22, 32, 64, 0.2) !important;
  transition: box-shadow 0.15s ease !important;
}
.padd {
  padding: 1rem;
}
.radio-color {
  color: #3a9ea5;
}
.radio-uncheck-color {
  color: #444c63;
}
.fontStyle {
  font-family: 'Lexend-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.32 px;
  font-style: normal;
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  outline: none;
  border: 1.5px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #3a9ea5;
}

input[type='radio']:checked {
  border-color: #3a9ea5;
}

input:-webkit-autofill {
  top: -15px !important;
}

.layoutsideShow {
  margin-left: 21rem;
}

.layoutside {
  margin-left: 5rem;
}

.errordrop {
  color: #c62828;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.2px;
  font-family: 'Lexend-Light';
}

.indicatorWidth {
  width: 80%;
}
.width-style {
  width: 89px !important;
}
.user-style {
  /* margin-left: 15rem; */
  align-items: center;
}
.victoryStyle {
  font-family: 'Lexend-Regular';
  font-style: normal;
  font-weight: bolder;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.32px;
  color: #3A9EA5;
}
.victorysubStyle {
  font-family: 'Lexend-Regular';
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 16px;
  color: #444c63;
}

.label-victory-style {
  font-weight: 500;
}
.scroll-tab {
  overflow-y: scroll;
}

.radio-daily {
  border: 1px solid !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 8px !important;
}

@media (max-width: 760px) {
  .layoutside {
    margin-left: 0rem !important;
  }
  .layoutsideShow {
    margin-left: 0rem !important;
  }
}
@media (min-width: 768px) {
  .layoutside {
    margin-left: 81px !important;
  }
}
@media (max-width: 930px) {
  .layoutsideShow {
    margin-left: 15rem;
  }
}
@media (max-width: 1019px) {
  .layoutsideShow {
    margin-left: 16rem;
  }
}
@media (min-width: 380px) {
  .indicatorWidth {
    width: 50%;
  }
}

@media (max-width: 380px) {
  .pr-6 {
    padding-right: 0.5rem !important;
    overflow: hidden;
  }
  .padd {
    padding: 0.1rem;
  }
}
