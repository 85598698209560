.togglediv {
  background: #fff8f4;
  border: none;
  padding: 15px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggleslider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggleslider {
  background-color: #3A9EA5;
}

input:focus + .toggleslider {
  box-shadow: 0 0 1px #3A9EA5;
}

input:checked + .toggleslider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded togglesliders */
.toggleslider.round {
  border-radius: 20px;
}

.toggleslider.round:before {
  border-radius: 50%;
}
